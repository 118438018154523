import React from 'react';

import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { withQuoteWizard } from '../components/withQuoteWizard';

import { SirWalterPage } from '../imageURLs';

import SEO from '../components/SEO';

import Post from '../components/Post/Post';
import Image from '../components/Image';
import Rating from '../components/Rating/Rating';
import CarouselSlider from '../components/Carousel/CarouselSlider';
import Compare from '../components/Compare/Compare';
import GreenCta from '../components/GreenCta';

import compareGroups from '../helpers/compareGroups';

const sliderItems = [
  {
    title: '',
    body: 'It’s thick lush coverage is great to lounge on',
    imagePath: SirWalterPage.slides.slide1,
  },
  {
    title: '',
    body: 'It looks beautiful in any space',
    imagePath: SirWalterPage.slides.slide2,
  },
  {
    title: '',
    body: 'It bounces back well from stress',
    imagePath: SirWalterPage.slides.slide3,
  },
];

const ratings = [
  {
    title: 'Low maintenance',
    score: 4,
  },
  {
    title: 'Shade tolerance',
    score: 5,
  },
  {
    title: 'Drought tolerance',
    score: 4,
  },
  {
    title: 'Weed and pest resistance',
    score: 4,
  },
];

const styles = theme =>
  createStyles({
    lawnProfileWrap: {
      padding: '90px 0px 0px 0px',
      backgroundColor: '#E9E9E9',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        padding: 0,
      },
    },
    partWrap: {
      marginTop: 20,
      marginBottom: 20,
    },
    icon: {
      minWidth: 180,
      [theme.breakpoints.up(370)]: {
        minWidth: 231,
      },
    },
  });

class ThePage extends React.Component {
  state = {
    displayRating: false,
  };

  onRatingVisibilityChange = isVisible => {
    console.log('here', isVisible);
    if (this.state.displayRating === false && isVisible === true) {
      this.setState({ displayRating: true });
    }
  };

  render() {
    const { classes, toggleQuoteComp, children } = this.props;

    return (
      <React.Fragment>
        <SEO
          title="Sir Walter DNA Certified - Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `varieties`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Post
          title="Sir Walter DNA Certified"
          subTitle="The allrounder"
          customerName="Tahni Scott"
          customerAddress="Northgate"
          bodyCopy="Sir Walter creates a lush, green space for our growing family – and it's great with our dog too."
          coverImage={SirWalterPage.hero}
          profileImage={SirWalterPage.happyCustomer}
          sticky
          noSpec
          toggleQuoteComp={toggleQuoteComp}
        />
        <Grid
          item
          container
          direction="column"
          justify="center"
          className={classes.lawnProfileWrap}
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            className={classes.partWrap}
            md={6}
          >
            <Grid item className={classes.partWrap} container justify="center">
              <Image
                relativePath="walter.png"
                className={classes.icon}
                imgStyle={{ width: '100%' }}
              />
            </Grid>

            <Grid item className={classes.partWrap}>
              <Typography variant="h4" align="center" component="h3">
                Lawn profile
              </Typography>
            </Grid>

            <Grid item container direction="column" alignItems="center"  alignContent="center">
              {ratings.map(rating => (
                <Rating
                  key={rating.title}
                  title={rating.title}
                  score={rating.score}
                />
              ))}
            </Grid>
          </Grid>

          <Grid item md={6}>
            <CarouselSlider largeImage inverted items={sliderItems} />
          </Grid>
        </Grid>
        <Grid item
          style={{ maxHeight: 750, overflow: 'hidden' }}>
          <Image
            relativePath={SirWalterPage.closeUp}
          />
        </Grid>
        <Compare compareGroups={compareGroups}
          defaultValue="sirWalter"
        />
        <GreenCta
          ctaTitle="Want to talk with a lawn expert?"
          ctaHref="tel:1800686711"
          ctaText="Call now"
          isExternalLink1={true}
          inverted
        />

        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage),true, true
);
